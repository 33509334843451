@charset 'utf-8';

$base-font-family:  sans-serif;
$base-font-size:    1rem;
$base-line-height:  1.5;

$code-font-family:  monospace;

$fg-color:          #ccc;
$fg-color-shade:    #888;
$bg-color:          #222;
$bg-color-shade:    #111;
$link-color:        #75a5c5;

$content-width:     48rem;


/* general */

html {
    font-size: $base-font-size;
    text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: $fg-color;
    background-color: $bg-color;
    scrollbar-gutter: stable both-edges;
}

body {
    max-width: $content-width;
    margin: 0 auto;
    // padding: 1rem;
    padding: 0;
    font-family: $base-font-family;
    line-height: $base-line-height;
}

a {
    text-decoration: none;
    color: $link-color;
}

a:hover {
    text-decoration: underline;
}

hr {
    border-color: $fg-color-shade;
}

li {
    margin-bottom: 0.5rem;
}


/* pages */

#page-title {
    margin-top: 0;
}

#page-title > a {
    color: $fg-color;
}

#links-list {
    list-style-position: outside;
    padding-left: 1rem;
}

#posts-list {
    list-style-position: outside;
    padding-left: 1rem;
    font-size: large;
}

#post-title {
    margin-bottom: 0.25rem;
}


/* code */

pre, code {
    font-family: $code-font-family;
    background-color: $bg-color-shade;
    color: $fg-color;
    overflow-x: auto;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
}

pre {
    padding: 0.25rem 0.5rem;
}

code {
    padding: 0 0.25rem;
}

pre > code {
    border: 0;
    padding: 0;
}

pre > p {
    margin: 0;
}


/* tables */

td, th {
    padding: 0.75rem;
    border: 1px solid #ddd;
}

tr:nth-child(even) {
    background-color: #f0f0f0;
}

th {
    text-align: left;
}


/* images */

img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}


/* videos */

.video {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 30px;
    overflow: hidden;
    text-align: center;
}

.video iframe,
.video object,
.video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* footnotes */

.footnotes {
    border-top: 1px solid $fg-color-shade;
}

/* temp */

#lambda {
    font-family: monospace;
    font-size: 8rem;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
    animation: c 30s infinite;
}

@keyframes c {
  0% { color: #070c10; }
  50% { color: #75a5c5; }
  100% { color: #070c10; }
}
